
































































































import Vue from 'vue';
import QuestionCard from './QuestionCard.vue';
import TVButton from '@/components/Presenter/TVButton.vue';
import SaveButton from '../SaveButton.vue';
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';
import { ViewerQuestion } from '@/api/interfaces.api';
import { DetectClick } from '@/utils/DetectClick';

export default Vue.extend({
  components: {
    QuestionCard,
    draggable,
    SaveButton,
    TVButton,
  },

  props: {
    column: {
      type: Object,
      required: true,
    },
  },
  directives: {
    DetectClick,
  },
  data() {
    return {
      drag: false,
      sortedQuestions: [],
      sortedQuestionActive: false,
      showInput: false,
      tempColumName: '',
    };
  },
  computed: {
    ...mapGetters({
      getQuestions: 'questions/getColumnQuestions',
      answers: 'questions/getAnswers',
      currentSession: 'presenter/getCurrentSession',
      tvData: 'presenter/getTVData',
    }),
    questions: {
      get(): ViewerQuestion[] {
        if (this.sortedQuestions.length > 0 || this.sortedQuestionActive) {
          return this.sortedQuestions;
        }
        return this.getQuestions(this.column.id);
      },
      set(value: any): void {
        this.sortedQuestionActive = true;
        this.sortedQuestions = value;
        this.$store.dispatch('questions/updateQuestionOrder', {
          newQuestionArr: this.sortedQuestions,
          columnId: this.column.id,
        });
        // TODO: Find better solution here?
        setTimeout(() => {
          this.sortedQuestions = [];
          this.sortedQuestionActive = false;
        }, 500);
      },
    },
  },

  methods: {
    toggleInput(): void {
      this.showInput = !this.showInput;
      if (this.showInput) {
        const refs = this.$refs as any;
        this.$nextTick(() => refs[`titleInput`].focus());
      }
    },
    updateColumnTitle(): void {
      this.$store.dispatch('questions/updateColumnName', {
        newColumnName: this.tempColumName,
        columnId: this.column.id,
      });
      this.showInput = false;
      this.tempColumName = '';
    },
    removeColumn(): void {
      if (
        confirm(
          this.$t(
            'components.presenter.questions.boardColumnConfirmMsg'
          ) as string
        )
      ) {
        this.$store.dispatch('questions/removeColumn', {
          columnId: this.column.id,
        });
      }
    },
  },
});
