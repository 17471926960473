import Vue from 'vue';

// This variable will hold the reference to
// document's click handler
let handleOutsideClick: any;
export const DetectClick = {
  bind(el: any, binding: any, vnode: any) {
    // Here's the click/touchstart handler
    // (it is registered below)
    handleOutsideClick = (e: any) => {
      e.stopPropagation();
      // Get the handler method name and the exclude array
      // from the object used in v-closable
      const { handler } = binding.value;

      if (!el.contains(e.target)) {
        // If the clicked element is outside the dialog
        // and not the button, then call the outside-click handler
        // from the same component this directive is used in
        vnode.context[handler]();
      }
    };
    // Register click/touchstart event listeners on the whole page
    document.addEventListener('click', handleOutsideClick);
    document.addEventListener('touchstart', handleOutsideClick);
  },
  unbind() {
    // If the element that has v-closable is removed, then
    // unbind click/touchstart listeners from the whole page
    document.removeEventListener('click', handleOutsideClick);
    document.removeEventListener('touchstart', handleOutsideClick);
  },
};

Vue.directive('closable', DetectClick);
