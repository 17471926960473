







































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import BoardColumn from '@/components/Presenter/Questions/BoardColumn.vue';
import draggable from 'vuedraggable';
export default Vue.extend({
  components: { BoardColumn, draggable },
  data() {
    return {
      selectedTabId: 0,
      sortedColumns: [],
      newColumnName: '',
    };
  },
  computed: {
    ...mapGetters({
      currentSession: 'presenter/getCurrentSession',
      labels: 'presenter/getLabels',
      columns: 'questions/getBoardColumns',
    }),
    computedColumns: {
      get(): any[] {
        if (this.sortedColumns.length > 0) {
          return this.sortedColumns;
        }
        return this.columns;
      },
      set(value: any): void {
        this.sortedColumns = value;
        this.$store.dispatch('questions/updateColumnOrder', this.sortedColumns);
        // TODO: Find better solution here?
        setTimeout(() => {
          this.sortedColumns = [];
        }, 500);
      },
    },
    computedTitleValue(): string {
      return (
        (this.currentSession.labels &&
          this.currentSession.labels['questions']) ||
        this.$t('views.presenter.session.questionsTitle')
      );
    },
  },
  methods: {
    createColumn() {
      this.$store.dispatch('questions/createColumn', {
        name: this.newColumnName,
        columnArr: this.computedColumns,
      });

      this.newColumnName = '';
    },
  },
});
