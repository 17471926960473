
































































































































































































import Vue from 'vue';
import SaveButton from '@/components/Presenter/SaveButton.vue';
import { mapMutations } from 'vuex';
export default Vue.extend({
  components: { SaveButton },
  props: {
    isHighlighted: {
      type: Boolean,
      required: false,
      default: false,
    },
    question: {
      type: Object,
      required: true,
    },
    answers: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapMutations({
      addAnswer: 'questions/ADD_QUESTION_ANSWER',
    }),
    toggleVisibility(questionId: string, state: boolean) {
      this.$store.dispatch('questions/toggleQuestionVisibility', {
        questionId,
        state,
      });
    },
    remove(questionId: string) {
      if (
        confirm(
          this.$t(
            'components.presenter.questions.questionCardDeleteMsg'
          ) as string
        )
      ) {
        this.$store.dispatch('questions/removeQuestion', {
          questionId,
        });
      }
    },
    saveAnswer(questionId: string, answer: string) {
      this.$store.dispatch('questions/updateQuestionAnswer', {
        questionId,
        questionAnswer: answer,
      });
    },
    toggleAnswered(questionId: string, state: boolean) {
      this.$store.dispatch('questions/toggleQuestionAnswered', {
        questionId,
        state,
      });
    },
    toggleBookmarked(questionId: string, state: boolean) {
      this.$store.dispatch('questions/toggleQuestionBookmarked', {
        questionId,
        state,
      });
    },
  },
});
